import React, { PureComponent, Fragment } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HanBot from './components/Hanbot';
import { auth } from './utils/cloudBase';
import { login, logout } from './redux/actions/userState';
import { db } from './utils/cloudBase';

import './App.css';

class App extends PureComponent {
    componentWillMount() {
        // console.log("app页面判断state:", this.props.userState)
        // console.log("localStorage:", localStorage.getItem("username"))
        // let localvalue = localStorage.getItem("username");
        // // let islogin = false;
        // // alert("localStorage:",localvalue)
        // // alert(typeof(localvalue))
        // if (localStorage.getItem("username") != null) {
        //     // 这样可以没有闪屏但是不安全，无法验证用户名合法性
        //       this.props.login();

        //     await db.collection('webreginfo')
        //         .doc(localStorage.getItem("username"))
        //         // .doc(333)
        //         .get()
        //         .then((res) => {
        //             // res.data 包含该记录的数据
        //             console.log(res.data);
        //             // console.log(res.data.length);
        //             // alert(res.data.length)
        //             // alert(JSON.stringify(res.data))
        //             // alert(typeof(res.data))
        //             if (res.data.length != 0) {
        //                 //   this.props.logout();
        //                 this.props.login();
        //                 // this.props.history.replace('/home');
        //                 // islogin =true;

        //             } 
        //             else {
        //                 //   this.props.login();
        //                 this.props.logout();
        //                 // islogin =true;

        //             }
        //             // if(res.data.length != 0){
        //             //     this.props.login();
        //             //     this.props.history.replace('/home');
        //             //     this.openLoginSuccess();
        //             // }

        //             // alert(JSON.stringify(res.data))
        //             // alert(typeof(JSON.stringify(res.data)))
        //         })
        //         // if(islogin){
        //         //     this.props.login();
        //         // }else{
        //         //     this.props.logout();
        //         // }

        // }else {
        //     this.props.logout();

        // }

    }



    componentDidMount() {
        // App组件挂载后，更新登录状态到redux
        // 需要更改判断条件，暂时不更改



        //         console.log("app页面判断state:",this.props.userState)
        //         console.log("localStorage:",localStorage.getItem("username"))
        //         db.collection("webreginfo")
        //           .doc(localStorage.getItem("username"))
        //           .get()
        //           .then((res) => {
        //     // res.data 包含该记录的数据
        //     console.log(res.data);
        //     console.log(res.data.length);
        //     // alert(res.data.length)
        //     // alert(JSON.stringify(res.data))
        //     // alert(typeof(res.data))
        //     if (res.data.length === 0) {
        //         this.props.logout();
        //     }else{
        //         this.props.login();
        //     }
        //     // if(res.data.length != 0){
        //     //     this.props.login();
        //     //     this.props.history.replace('/home');
        //     //     this.openLoginSuccess();
        //     // }

        //     // alert(JSON.stringify(res.data))
        //     // alert(typeof(JSON.stringify(res.data)))
        //   })
        // if (localStorage.getItem("username")) {
        //     // 已经登录
        //     this.props.login();
        // } else {
        //     // 未登录
        //     this.props.logout();
        // }
    }
    render() {
        return (
            // <div className="background">
            <div>
                
                <Fragment>
                    <Route path="/hanbot" component={HanBot} /> 
                    <Redirect to="/hanbot" />
                </Fragment>
                {/* <div className="ppt" onClick={this.ppt}>
                    我是下载</div> */}
                {/* <div className="appBox"> */}
                    {/* 路由鉴权：渲染/welcome页面 or /home页面 */}
                    {/* <Switch>
                        {this.props.userState ? (
                            <Fragment>
                                <Route path="/home" component={Home} />
                                <Redirect to="/home" />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Route path="/welcome" component={Welcome} />
                                <Redirect to="/welcome" />
                            </Fragment>
                        )}
                    </Switch> */}
                {/* </div> */}
                {/* <Footer /> */}
            </div>
        );
    }
}

export default withRouter(
    connect(
        // 状态
        state => ({
            userState: state.userState,
            isDark: state.personalData.isDark,
        }),
        // 操作状态的方法
        { login, logout }
    )(App)
);
