import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from '../../utils/cloudBase';
import formatTime from '../../utils/utils';
import axios from 'axios';
import { Rate, message, Skeleton, Tooltip, Modal, notification, Tag, Table, Affix, BackTop, List, Input, Button, Space, Card, Divider, Drawer, Col, Row, Form, Popconfirm, Typography, Progress, Pagination } from 'antd';

import {
    CheckOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    SearchOutlined,
    MehTwoTone, FrownTwoTone, SmileTwoTone,
    NotificationTwoTone,NotificationOutlined,
    FormOutlined, CloudUploadOutlined,
    QqCircleFilled,ShoppingCartOutlined,
    TrophyOutlined,
} from '@ant-design/icons';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import 'animate.css';
import reqwest from 'reqwest';

const { Paragraph } = Typography;
const { Search } = Input;
const { Meta } = Card;
const backtopstyle = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 6,
    backgroundColor: '#13227a',
    color: '#fff',
    textAlign: 'center',
    fontSize: 28,
};

const { TextArea } = Input;


class HanBot extends React.Component {
    state = {
        //all heros data
        allchampions: [],
        // data: [],
        loading: false,
        // search frame state
        isSearch: false,
        // search result
        searchresult: [],
        // the supported AIO for each hero
        searchcontent: {},
        // the display drawer hero name
        searchtitle: '',
        // the display drawer hero avatar url
        searchimg: '',
        // the drawer display or not
        drawvisible: false,
        // the feedback window display or not
        feedbackvisible: false,
        // textarea右侧的完成状态,现在没用
        feedbackbutton: 1,
        // skeletonloading: true,
        // the Ranking data
        aioranking: [],
        // the Ranking window display or not
        rankingvisible: false,
        // 功能性函数的变量
        arrtemp: [],
    };


    componentWillMount() {
        this.getAllChampions();
        
    }


    componentDidMount() {

        if (JSON.parse(localStorage.getItem("VoteUpData")) == null) {
            localStorage.setItem("VoteUpData", JSON.stringify({}))
        };
        if (JSON.parse(localStorage.getItem("VoteDownData")) == null) {
            localStorage.setItem("VoteDownData", JSON.stringify({}))
        };
        // axios.post(`http://127.0.0.1:5002/add`,{
        //        title: '测试post6'
        //     }).then(
        //                 response => {
        //                    //请求成功后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,users:response.data.items})
        //                     console.log("axios的post结果：",response.data)
        //                  },
        //                  error => {
        //                      //请求失败后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,err:error.message})
        //                     console.log("axios的post错误：",error.message)
        //                }
        // )
        // axios.get(`/api1/search/users?q=${keyWord}`).then(
        // axios.get(`http://127.0.0.1:4523/mock/942500/pet/1`).then(
        //                 response => {
        //                    //请求成功后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,users:response.data.items})
        //                     console.log("axios的get结果：",response.data)
        //                  },
        //                  error => {
        //                      //请求失败后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,err:error.message})
        //                     console.log("axios的get错误：",error.message)
        //                }
        // )
        // axios.post(`http://127.0.0.1:4523/mock/946692/hanbotfeedback`,{
        //        _id: '666',
        //        hbfbcontent: '测试post',
        //        submittime:'2022年5月6日13时56分47秒'
        //     }).then(
        //                 response => {
        //                    //请求成功后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,users:response.data.items})
        //                     console.log("axios的post结果：",response.data)
        //                  },
        //                  error => {
        //                      //请求失败后通知App更新状态
        //                     //  this.props.updateAppState({isLoading:false,err:error.message})
        //                     console.log("axios的post错误：",error.message)
        //                }
        // )
    }


    // 搜索功能函数
    // serach function
    goSearch = (searchkeys) => {
        this.setState({
            isSearch: true
        })
        if (searchkeys.length != 0) {
           
            const _ = db.command;
            const $ = db.command.aggregate;
            // 注意学习云数据库操作方法
            db.collection("hanbotaio").where(_.or([_.and([
            // db.collection("hanbotaio").aggregate().unwind('$champion').match(_.or([_.and([

                {
                    name: db.RegExp({
                        regexp: '.*' + searchkeys,
                        options: 'i',
                    })
                },
                
            ]),
            {
                cname: db.RegExp({
                    regexp: '.*' + searchkeys,
                    options: 'i',
                })
            },
            {
                champions: db.RegExp({
                    regexp: '.*' + searchkeys,
                    options: 'i',
                })
            }
                // 以后题量大要改limit
            ])
            ).limit(1000).get().then(res => {

                // console.log(res.data)

                this.setState({
                    searchresult: res.data,
                    isSearch: false,
                    drawvisible: false,

                });
            })
            // db.collection("hanbotaio").aggregate().unwind('$champion').end().then(
            //     res => {
            //         console.log(res.data)

            //     }
            // )
        } else {
            // 为空时或者清空时
            this.setState({
                // data: [],
                searchresult: [],
                isSearch: false,
                drawvisible: false,

            });
        }
    }

    //搜索框变化时回调，兼容键盘退回键清空
    // search value change function
    goChange = (value) => {
        //   console.log("搜索框内容：",value.target.value)
        //   console.log("搜索框内容：",value.target.value.length)
        if (value.target.value.length == 0) {
            this.setState({
                searchresult: [],
                isSearch: false,
                drawvisible: false,

            });
        }
    }

    // ---------------------------------------------------------------------------------------------------

    // display drawer function
    showDrawer = (item) => {
        // Object.entries(item.champion).foreach((items)=>{
        //      return  console.log("英雄名字：",items)
        // })
        // console.log("英雄名字：", item.champion);
        // 每次打开都是最新赞数据
        this.getRate(item.name);
        this.setState({
            drawvisible: true,
            // 优化渲染两次，但是会弹
            searchcontent: item.champion,
            searchtitle: item.name,
            //   searchimg: item.name + '.png',
            searchimg: require('./championsavatar/' + item.name + '.png').default,
            
        });
    };


    // close drawer function
    onClose = () => {
        this.setState({
            drawvisible: false,
            // myanswercontent: '',
            // myanswertitle: '',
            searchcontent: {},
            searchtitle: '',
        });
    };

    // get all champions data function
    getAllChampions = () => {
        db.collection("hanbotaio")
            //   .doc(localStorage.getItem("username"))
            .limit(1000)
            .get()
            .then((res) => {
                
                if (res.data.length == 0) {
                    this.setState({
                        allchampions: [],
                    })
                } else {
                    this.setState({
                        allchampions: res.data,
                        // skeletonloading: false,
                    })
                }

                // 功能性：获取不重复aio总名称
                // let arrtemps = new Array(0);
                // let that = this;
                // res.data.map(function (item, index) {
                //     console.log("1",item.champion)
                //     Object.keys(item.champion).map((item, index) => {
                //     console.log("2",item)
                //     arrtemps.push(item)
                //     console.log(new Set(arrtemps))
                //     })
                // })

                // 功能性：获取每个英雄对应支持合集
                // res.data.map(function (item, index) {
                //     console.log("1",item.champion)
                //     let arrtemps = new Array(0);
                //     Object.keys(item.champion).map((item, index) => {
                //     console.log("2",item)
                //     arrtemps.push(item)
                //     console.log("内:",arrtemps)
                //     })
                //     console.log("外:",arrtemps)
                //     db.collection('hanbotaio')
                //     .where({
                //         name: item.name
                //     })
                //     .update({
                //         "champions": arrtemps

                //     }).then(res => {
                //         console.log("更新成功")
                //     })

                // })

            })
    }

    // 合集点赞排行榜
    // get Ranking data function
    getAioRanking = () => {
        db.collection("hanbotaioranking")
        .orderBy("aioupcount","desc")
        .limit(3)
        .get()
        .then((res) => {
            // console.log("排行榜:",res.data)
            this.setState({
                    rankingvisible: true,
                    aioranking: res.data,
            })
        })
    }

    // up推荐 Vote: wonderful  function
    aioUp = (heroname, aioname) => {
        // let cancelcollectid = options.currentTarget.dataset.cancelcollectid;
        // console.log("点赞英雄名：", heroname);
        // console.log("点赞合集名：", aioname);

        let aionamedata = aioname;
        let heronamedata = heroname;
        let storageupdata = JSON.parse(localStorage.getItem("VoteUpData"));
        // console.log(storageupdata)
        // console.log(storageupdata[heronamedata] == undefined)
        // console.log("localStorage:", JSON.parse(localStorage.getItem("VoteData"))[heroname])
        const _ = db.command;

        if (storageupdata[heronamedata] == undefined) {
            // 这里是不存在当前英雄的记录
            // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
            storageupdata[heronamedata] = [aionamedata],
                // localStorage.setItem("VoteUpData",JSON.stringify(storageupdata))
                // 不存在当前英雄记录，直接存入数据库就行了
                db.collection('hanbotaio')
                    .where({
                        name: heroname
                    })
                    
                    .update({
                        //为了和postId一致，故转为string类型
                        
                        // 牛逼！！！！！学习注意看文档
                        "champion": {
                            // 学习！！！变量,key作为变量时为什么要加中括号
                            [aionamedata]: {
                                "up": _.inc(1),
                            }
                        },
                        "upcount":_.inc(1),

                    }).then(res => {
                        
                        
                        localStorage.setItem("VoteUpData", JSON.stringify(storageupdata))
                        this.getRate(heroname);
                        message.config({
                            maxCount: 1
                        })
                        message.success({
                            content: 'success',
                            // duration: 0,
                            className: 'votemessagestyle',
                            style: {
                                marginTop: '20vh',
                            },
                        });
                        // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
                        // message.success('点赞成功！');
                    })
                    // 记录总点赞数
                    db.collection('hanbotaioranking')
                    .where({
                        aio: aionamedata
                    })
                    //  .doc(idenid)
                    //  .set({
                    .update({
                        "aioupcount":_.inc(1),

                    }).then(res => {
                        
                    })
        } else {
            // 这里是存在当前英雄的记录
            // 还要继续判断是否存在当前合集记录
            let storageAioupdata = storageupdata[heronamedata]
            // console.log("storageAioupdata",storageAioupdata)
            // console.log(storageAioupdata.some(item => item === aionamedata))
            if (storageAioupdata.some(item => item === aionamedata)) {
                // 如果存在当前合集记录，提示不要重复投票
                // console.log("不要重复投票")
                message.config({
                    maxCount: 1
                })
                message.warn({
                    content: <div><div>不要重复投票</div><div>Don't vote twice</div></div>,
                    // duration: 0,
                    // maxCount: 1,
                    className: 'votemessagestyle',
                    style: {
                        marginTop: '20vh',
                    },
                });
            } else {
                // 如果不存在当前合集记录
                // 那么就插入该合集记录
                storageAioupdata.push(aionamedata);
                // console.log("storageAioupdata2",storageAioupdata)
                // 把合集总记录value更新至对应key值
                storageupdata[heronamedata] = storageAioupdata;
                // console.log(storageupdata)
                // 然后更新至localstorage
                // localStorage.setItem("VoteUpData",JSON.stringify(storageupdata))
                // 存入数据库
                db.collection('hanbotaio')
                    .where({
                        name: heroname
                    })
                    .update({
                        "champion": {
                            [aionamedata]: {
                                "up": _.inc(1),
                            }
                        }

                    }).then(res => {
                        // 每个题目的收藏数量
                        this.getRate(heroname);
                        message.success({
                            content: 'success',
                            // duration: 0,
                            // maxCount: 1,
                            className: 'votemessagestyle',
                            style: {
                                marginTop: '20vh',
                            },
                        });
                        // 然后更新至localstorage
                        localStorage.setItem("VoteUpData", JSON.stringify(storageupdata));
                        // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
                        // message.success('点赞成功！');
                    })
            }
        }

    };

    // down踩 Vote: terrible function
    aioDown = (heroname, aioname) => {
        // let cancelcollectid = options.currentTarget.dataset.cancelcollectid;

        // console.log("踩合集名：", aioname);
        // console.log("踩英雄名：", heroname);
        let heronamedata = heroname;
        let aionamedata = aioname;


        let storagedowndata = JSON.parse(localStorage.getItem("VoteDownData"));
        // console.log(storagedowndata)
        // console.log(storagedowndata[heronamedata] == undefined)
        // console.log("localStorage:", JSON.parse(localStorage.getItem("VoteData"))[heroname])
        const _ = db.command;

        if (storagedowndata[heronamedata] == undefined) {
            // 这里是不存在当前英雄的记录
            // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
            storagedowndata[heronamedata] = [aionamedata],
                // localStorage.setItem("VoteUpData",JSON.stringify(storageupdata))
                // 不存在当前英雄记录，直接存入数据库就行了
                db.collection('hanbotaio')
                    .where({
                        name: heroname
                    })
                    .update({
                        "champion": {
                            [aionamedata]: {
                                "down": _.inc(1),
                            }
                        }

                    }).then(res => {
                        localStorage.setItem("VoteDownData", JSON.stringify(storagedowndata))
                        this.getRate(heroname);
                        message.config({
                            maxCount: 1
                        })
                        message.success({
                            content: 'success',
                            // duration: 0,
                            className: 'votemessagestyle',
                            style: {
                                marginTop: '20vh',
                            },
                        });
                        // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
                        // message.success('点赞成功！');
                    })
        } else {
            // 这里是存在当前英雄的记录
            // 还要继续判断是否存在当前合集记录
            let storageAiodowndata = storagedowndata[heronamedata]
            // console.log(storageAiodowndata)
            // console.log(storageAiodowndata.some(item => item === aionamedata))
            if (storageAiodowndata.some(item => item === aionamedata)) {
                // 如果存在当前合集记录，提示不要重复投票
                // console.log("不要重复投票")
                message.config({
                    maxCount: 1
                })
                message.warn({
                    content: <div><div>不要重复投票</div><div>Don't vote twice</div></div>,
                    // duration: 0,
                    className: 'votemessagestyle',
                    maxCount: 1,
                    style: {
                        marginTop: '20vh',
                    },
                });
            } else {
                // 如果不存在当前合集记录
                // 那么就插入该合集记录
                // storageAiodowndata[heronamedata] = storageAiodowndata.push(aionamedata);
                storageAiodowndata.push(aionamedata);
                // console.log(storageAiodowndata)
                // 把合集总记录value更新至对应key值
                storagedowndata[heronamedata] = storageAiodowndata;
                // console.log(storagedowndata)
                // 然后更新至localstorage
                // localStorage.setItem("VoteUpData",JSON.stringify(storageupdata))
                // 存入数据库
                db.collection('hanbotaio')
                    .where({
                        name: heroname
                    })
                    .update({
                        "champion": {
                            [aionamedata]: {
                                "down": _.inc(1),
                            }
                        }

                    }).then(res => {
                        this.getRate(heroname);
                        message.success({
                            content: 'success',
                            // duration: 0,
                            className: 'votemessagestyle',
                            style: {
                                marginTop: '20vh',
                            },
                        });
                        // 然后更新至localstorage
                        localStorage.setItem("VoteDownData", JSON.stringify(storagedowndata));
                        // localStorage.setItem("VoteData",JSON.stringify({[heroname]:[aionamedata]}))
                        // message.success('点赞成功！');
                    })
            }
        }

    };

    // 获取英雄合集 赞 踩 的数量，实现局部更新
    // get hero data which you click
    getRate = (heronamerate) => {

        db.collection('hanbotaio')
            .where({
                name: heronamerate
            })
            .get()
            .then(res => {
                this.setState({
                    searchcontent: res.data[0].champion,
                })
            })
    };


    // notification function
    webNotice = () => {
        notification.config({
            maxCount: 1,

        });
        notification.open({
            message: 'Notification 公告',
            description:
                <div>
                    <p style={{ color: 'red', fontSize: '20px' }}>该应用非营利性质,仅供学习交流使用,任何商业活动均与本人无关.</p>
                    <p>合集支持数据统计截止于2022年8月16日,不定期更新数据.部分长时间未更新的合集不在统计范围.</p>
                    <p>每个合集评分数据仅供参考,不要认真.</p>
                    <p>为了更好的使用每个合集和服务于广大**,请认真合理的评分、不要乱刷.</p>
                    <p>有任何建议、网站BUG或者合集数据统计不全问题,请点击公告按钮的左侧按钮反馈.</p>
                </div>,


            className: 'noticestyle',
            duration: 12,
            closeIcon: <CloseCircleOutlined style={{ color: '#000', fontSize: '16px' }} />,
            // maxCount: 1, 这里设置无效，必须config里面设置
            style: {
                width: '30vw',
                top: '10vh',
                //   right: '2vw'
                //   border: '2px solid #000',

            },
        });
    };


    // close Ranking window
    handleRankingCancel = () => {
        //点击取消按钮触发的事件
        this.setState({
            rankingvisible: false,
        });
    }

    // display Feedback window function
    webFeedback = () => {
        this.setState({
            feedbackvisible: true,
            drawvisible: false,
        })
    }

    // close Feedback window function
    handleFeedbackCancel = () => {
        //点击取消按钮触发的事件
        this.setState({
            feedbackvisible: false,
            feedbackstatus: "",
            feedbackbutton: 1
        });
    }

    // feedback value change function
    handleFeedbackChange = () => {
        this.setState({
            feedbackstatus: "",
            feedbackbutton: 1
        });
    }

    // feedback submit function
    handleFeedbackSubmit = (values) => {
        // console.log("提交内容：", values.feedback)
        // console.log("提交内容：",values.target.value)

        // 提交内容后将输入框状态设为提交中validating
        this.setState({
            feedbackstatus: "validating",
            feedbackbutton: 2
        });

        db.collection("hanbotfeedback")
            .add({
                hbfbcontent: values.feedback,
                submittime: formatTime(new Date())
            })
            .then(res => {
                // 提交内容成功后将输入框状态设为成功success
                this.setState({
                    feedbackstatus: "success",
                    feedbackbutton: 3
                });
            })
        // setTimeout(
        //     ()=>{
        // // 提交内容成功后将输入框状态设为成功success
        // this.setState({
        //     feedbackstatus: "success"
        // });
        //     }, 3000);

    }


    // 功能函数，设置_id
    // copyName = () => {
    //     db.collection("hanbotaio")
    //         .field({
    //             name: true,
    //         })
    //         .limit(2000)
    //         .get()
    //         .then((res) => {
    //             console.log("hanbotaio数据库：", res.data);
    //             for (let i = 0; i < res.data.length; i += 1) {
    //                 console.log(res.data[i].name)
    //                 let tempname = res.data[i].name;
    //                 db.collection("hanbotaio")
    //                     .where({
    //                         name: tempname
    //                     })
    //                     .update({
    //                         ceshi: tempname,
    //                     })
    //             }
    //         })
    // };



    render() {
        // const aio = [];

        const headstyle = {
            background: 'transparent',
            zIndex: '6',
            padding: '0',
            // border: 'none'
        }

        const drawstyle = {
            height: 'auto',
            // minHeight: '20vh',
            maxHeight: '50vh',
            width: '55vw',
            bottom: '6vh',
            // boxShadow: '0px -22px 20px -12px #515151 inset',
            // overflow: 'hidden',
            // boxSizing: 'content-box',

            // border: '2px solid red',
            // backgroundColor: '#e1e1e1',

            // left: '50%'
            // margin: '0 auto'
        }

        const drawcontentstyle = {
            backgroundColor: '#e1e1e1',
            border: '2px solid #000',
            // backgroundColor: '#e1e1e1',
            // 控制content能滑动
            // height: 'auto',
            maxHeight: '50vh',
            // boxShadow: '0px -22px 20px -12px #515151 inset',
            // overflow: 'auto',
            zIndex: '4',
            // overflow: 'hidden',

            // boxSizing: 'content-box',

        }

        const drawbodystyle = {
            padding: '18px 0 18px 0',
            // height: 'auto',
            // boxShadow: '0px -22px 20px -12px #515151 inset',
            // maxHeight: '50vh',

            // position: 'relative',
            // top: '20px',
            overflow: 'hidden',

            // boxSizing: 'content-box',
            // zIndex: '6',

            // position: 'relative',
            // width: '100%',
            // border: '2px solid #000',

        }

        const hiddenstyle = {
            visibility: 'hidden',
        }

        const modalcontentstyle = {
            border: '2px solid #000',

        }

        const customIcons = {
            1: <FrownTwoTone twoToneColor="#2c2c2c" />,
            2: <MehTwoTone />,
            3: <SmileTwoTone twoToneColor="#f4ea2a" />,
        };

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        return (
            <div>
                <div>
                    {/* 标题导航栏 */}
                    <div className="nav-top">
                        <div className="hanbot-logo">

                            <div className="animate__animated animate__bounceInLeft">
                                <img src={require('./misc/hanbotlogo.png').default} alt='' />


                            </div>
                        </div>
                        <span className='logobox'>

                            <div className='logoboxupbox'>
                                <div className="animate__animated animate__bounceInLeft animate__delay-1s">

                                    <img className='logoboxup' src={require('./misc/hanbotlogo2.png').default} alt='' />

                                </div>
                            </div>
                            <div>
                                <div className="animate__animated animate__bounceInLeft animate__delay-2s">

                                    <img className='logoboxdown' src={require('./misc/hanbotlogo3.png').default} alt='' />

                                </div>
                            </div>


                        </span>
                        <Search
                            placeholder="keywords or aio..."
                            bordered={false}
                            className="searchstyle"
                            allowClear
                            loading={this.state.isSearch}
                            onSearch={this.goSearch}
                            onChange={this.goChange}
                            style={{ width: '20vw' }}
                        />
                        <div className="nav_ranking" onClick={this.getAioRanking}> 
                            <Tooltip zIndex={10001} title="Ranking 排行榜" autoAdjustOverflow={true} placement="bottomRight" arrowPointAtCenter="true" getPopupContainer={() => document.querySelector('.nav-top')}>
                                <TrophyOutlined className='animate__animated animate__bounce animate__delay-3s' style={{ color: "#eb2f96" }} />
                            </Tooltip>
                        </div>
                        <Modal title="Ranking 排行榜"
                            visible={this.state.rankingvisible}   //设置默认隐藏
                            onCancel={this.handleRankingCancel}  // 点击取消按钮，对话框消失
                            footer={false}   //对话框的底部
                            maskClosable={true}
                            centered
                            destroyOnClose={true}
                            // getContainer={false}
                            // bodyStyle = {modalcontentstyle}
                            // confirmLoading = {true}
                            // 包括了遮罩层，是外面最大的容器
                            // wrapClassName = "modalstyle"
                            // style和classname作用范围一样
                            // style = {modalcontentstyle}
                            // className = "modalstyle"
                            closeIcon={<CloseCircleOutlined style={{ color: '#000', fontSize: '16px' }} />}
                        >
                            {this.state.aioranking.map(function (item, index) {
                                    switch(index){
                                        case 0:
                                            return (
                                                <div>
                                                        <div className="rankingstyle animate__animated animate__fadeInDown">
                                                            <span className='rankingimgbox'><img className='rankingimg' src={require('./misc/Gold.png').default} alt='' /></span>
                                                            <span className='swingstyle animate__animated animate__swing animate__delay-1s'>{item.aio}</span>
                                                            <span>
                                                                <div className='rankingtotal'>
                                                                  <span className="rankingimgheartbox animate__animated animate__rubberBand">
                                                                    <img className="rankingimgheart" src={require('./misc/up_fill.png').default} />
                                                                  </span>
                                                                  <span className='rankingsumgold'>{item.aioupcount}</span> 
                                                                </div>
                                                            </span>
                                                            
                                                        </div>
                                                </div>
                                            )
                                        case 1:
                                            return (
                                                <div>
                                                        <div className="rankingstyle animate__animated animate__fadeInDown">
                                                            <span className='rankingimgbox'><img className='rankingimg' src={require('./misc/Silver.png').default} alt='' /></span>
                                                            <span>{item.aio}</span>
                                                            <span>
                                                                <div className='rankingtotal'>
                                                                  <span className="rankingimgheartbox animate__animated animate__rubberBand">
                                                                    <img className="rankingimgheart" src={require('./misc/up_fill.png').default} />
                                                                  </span>
                                                                  <span className='rankingsumsilver'>{item.aioupcount}</span> 
                                                                </div>
                                                            </span>
                                                        </div>
                                                </div>
                                                )
                                        case 2:
                                            return (
                                                <div>
                                                        <div className="rankingstyle animate__animated animate__fadeInDown">
                                                            <span className='rankingimgbox'><img className='rankingimg' src={require('./misc/Bronze.png').default} alt='' /></span>
                                                            <span>{item.aio}</span>
                                                            <span>
                                                                <div className='rankingtotal'>
                                                                  <span className="rankingimgheartbox animate__animated animate__rubberBand">
                                                                    <img className="rankingimgheart" src={require('./misc/up_fill.png').default} />
                                                                  </span>
                                                                  <span className='rankingsumbronze'>{item.aioupcount}</span> 
                                                                </div>
                                                            </span>
                                                        </div>
                                                </div>
                                            )
                                    }
                                    
                                }.bind(this))}
                        </Modal>
                        <div className="nav_feedback" onClick={this.webFeedback}>
                            <Tooltip zIndex={10001} title="Feedback 反馈" autoAdjustOverflow={true} placement="bottomRight" arrowPointAtCenter="true" getPopupContainer={() => document.querySelector('.nav-top')}>
                                <FormOutlined className='animate__animated animate__bounce animate__delay-4s' style={{ color: "#eb2f96" }} />
                            </Tooltip>
                        </div>
                        <Modal title="Feedback 反馈"
                            visible={this.state.feedbackvisible}   //设置默认隐藏
                            onCancel={this.handleFeedbackCancel}  // 点击取消按钮，对话框消失
                            footer={false}   //对话框的底部
                            maskClosable={false}
                            centered
                            destroyOnClose={true}
                            // getContainer={false}
                            // bodyStyle = {modalcontentstyle}
                            // confirmLoading = {true}
                            // 包括了遮罩层，是外面最大的容器
                            // wrapClassName = "modalstyle"
                            // style和classname作用范围一样
                            // style = {modalcontentstyle}
                            // className = "modalstyle"
                            closeIcon={<CloseCircleOutlined style={{ color: '#000', fontSize: '16px' }} />}
                        >
                            <Form
                                onFinish={this.handleFeedbackSubmit}
                            // className="feedbackbox"
                            >
                                {/* 必须有name才能拿到值 */}
                                < Form.Item
                                    name="feedback"
                                    rules={[{
                                        required: true,
                                        message: 'Please input sth ...',
                                    }]}
                                //   hasFeedback
                                //   validateStatus={this.state.feedbackstatus}
                                >
                                    <TextArea
                                        showCount={true}
                                        rows={8}
                                        autoFocus
                                        bordered={false}
                                        allowClear
                                        id='feedbackarea'
                                        className='feedbacktextarea'
                                        onChange={this.handleFeedbackChange}
                                    // 这里按回车能拿到textarea的value值,为target.value
                                    // onPressEnter={(value)=>this.handleFeedbackSubmit(value)}
                                    />
                                </Form.Item>
                                <Form.Item  >
                                    {this.state.feedbackbutton == 1 ?
                                        <Tooltip zIndex={10001} title="Submit" placement="top" arrowPointAtCenter="true">

                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                //  ghost 
                                                shape='circle'
                                                //  loading
                                                // block
                                                className='feedbackstyle'
                                            >
                                                <CloudUploadOutlined />
                                            </Button>
                                        </Tooltip>
                                        :
                                        (this.state.feedbackbutton == 2 ?
                                            <Button
                                                type="primary"
                                                //  htmlType="submit" 
                                                //  ghost 
                                                shape='circle'
                                                //  loading
                                                // block
                                                // disabled
                                                className='feedbackstyleup'
                                            >
                                                <SyncOutlined spin />
                                            </Button>
                                            :
                                            <Button
                                                type="primary"
                                                //  htmlType="submit" 
                                                //  ghost 
                                                shape='circle'
                                                //  loading
                                                // block
                                                // disabled
                                                className='feedbackstylefinish'
                                            >
                                                <CheckOutlined />
                                            </Button>
                                        )

                                    }



                                </Form.Item>
                            </Form>
                        </Modal>
                        <div className="nav_notice" onClick={this.webNotice}>
                            <Tooltip zIndex={10001} title="Notification 公告" autoAdjustOverflow={true} placement="bottomRight" arrowPointAtCenter="true" defaultVisible="true" getPopupContainer={() => document.querySelector('.nav-top')}>
                                {/* <NotificationTwoTone className='animate__animated animate__bounce animate__delay-2s' twoToneColor="#eb2f96" /> */}
                                <NotificationOutlined className='animate__animated animate__bounce animate__delay-5s' style={{ color: "#eb2f96" }} />
                            </Tooltip>
                        </div>
                        {/* </Space> */}
                    </div>
                    <div className="nav-top-back">
                        {/* <div class="hanbot-logo">HanBot Supported Champions</div> */}
                    </div>
                    {this.state.searchresult.length == 0 ?
                        <Space className="area">
                            <div className="champions-area">
                                {this.state.allchampions.map(function (item, index) {
                                    // console.log("我是map循环：", Object.keys(item.champion).length);
                                    // let path = './championsavatar/' + item.name + '.png';
                                    let imgUrl = require('./championsavatar/' + item.name + '.png').default;
                                    return (
                                        <div className="hero animate__animated animate__fadeInDown" onClick={() => this.showDrawer(item)} key={item.id}>
                                            <img src={imgUrl} alt='' />
                                            <Tooltip zIndex={10001} title="Total" placement="top">
                                                {/* 获取对象长度 */}
                                                <div className="herocornersum">{Object.keys(item.champion).length}</div>
                                            </Tooltip>
                                        </div>
                                    )
                                }.bind(this))}
                                {/* 实现自动换行最后一行左对齐 */}
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>



                            </div>
                            <Drawer
                                // height={500}
                                headerStyle={headstyle}
                                drawerStyle={drawcontentstyle}
                                contentWrapperStyle={drawstyle}
                                bodyStyle={drawbodystyle}
                                // getContainer={false}
                                className="details"
                                placement="bottom"
                                destroyOnClose={true}
                                closable={true}
                                closeIcon={<div className='closeimg'></div>}
                                onClose={this.onClose}
                                visible={this.state.drawvisible}
                            >
                                {/* <p className="search_title" style={{ marginBottom: 24 }}> */}
                                {/* 弹出框标题 英雄名称和头像 */}
                                <div className='drawtitle'>

                                    <div className='drawtitlebox'>
                                        <p className='animate__animated animate__fadeInLeft'><img className='drawimg' src={this.state.searchimg} alt='' /></p>
                                        <p className='search_title animate__animated animate__fadeInRight'>{this.state.searchtitle}</p>
                                    </div>
                                    <Divider orientation="left" className='drawdivider'></Divider>
                                </div>
                                {/* 内容部分 合集名称和点赞 踩 */}
                                <div className='drawcontent'>
                                    {/* 判断是否有支持合集 */}
                                    {Object.keys(this.state.searchcontent).length == 0 ?
                                        
                                     <div><p className="search_content_not animate__animated animate__fadeInDown">不支持 ( Not Supported ) </p></div>
                                        
                                     :
                                     Object.keys(this.state.searchcontent).map((item, index) => {
                                            switch (item) {
                                                //收费合集判断
                                                case 'SAAIO':
                                                // case 'AlexisAIO':
                                                case 'GGAIO':
                                                case 'IntAIO':
                                                case 'MarksmanAIO':
                                                case '[Xi]Vayne':
                                                case 'HGAIO':
                                                case 'RexEzreal':
                                                case 'US':
                                                case 'Yasuo-Forbidden Blade':
                                                case 'Yasuo-Windwalker':
                                                case 'SP-AIO':
                                                case 'JK-Pvt-AIO':
                                                    return (
                                                        <div className='search_content_paid_box animate__animated animate__fadeInDown'>
                                                            <p><img className='search_content_paid' src={require('./misc/coin-Dollar.png').default} alt=''></img></p>
                                                            <p className="search_content">{item}</p>
                                                            <span className="search_content_rate_box">
                                                                <div className='ratebox'>
                                                                    <Tooltip title="Vote: wonderful" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>
                                                                            <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                </div>
                                                                <div className='ratebox'>
                                                                    <span className='filldown'></span>
                                                                    <Tooltip title="Vote: terrible" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>
                                                                            <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>*</span>
                                                                    <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    );
                                                    break;
                                                    case 'AlexisAIO':
                                                    return (
                                                        <div className='search_content_paid_box animate__animated animate__fadeInDown'>
                                                            <p><img className='search_content_paid' src={require('./misc/coin-Dollar.png').default} alt=''></img></p>
                                                            <p className="search_content">{item}</p>
                                                            <p className='alexisqq'>
                                                              <Tooltip title="click to buy" placement="top">
                                                                 <a href="https://alexisaio.com/" target="_blank" rel="noopener noreferrer"><ShoppingCartOutlined /></a>
                                                              </Tooltip>
                                                            </p>

                                                            <span className="search_content_rate_box">
                                                                <div className='ratebox'>
                                                                    <Tooltip title="Vote: wonderful" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>
                                                                            <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                </div>
                                                                <div className='ratebox'>
                                                                    <span className='filldown'></span>
                                                                    <Tooltip title="Vote: terrible" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>
                                                                            <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                        </span>
                                                                        {/* </span> */}
                                                                    </Tooltip>
                                                                    <span className='ratesum'>*</span>
                                                                    <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                </div>
                                                            </span>


                                                        </div>
                                                    );
                                                    break;
                                                default:
                                                    return (
                                                        <div className='search_content_free_box animate__animated animate__fadeInDown'>
                                                            <p><img className='search_content_free' src={require('./misc/coin-Free.png').default} alt=''></img></p>
                                                            <p className="search_content">{item}</p>
                                                            <span className="search_content_rate_box">
                                                                <div className='ratebox'>
                                                                    <Tooltip title="Vote: wonderful" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                </div>

                                                                <div className='ratebox'>
                                                                    <span className='filldown'></span>

                                                                    <Tooltip title="Vote: terrible" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                        </span>
                                                                        {/* </span> */}
                                                                    </Tooltip>
                                                                    <span className='ratesum'>*</span>
                                                                    <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                </div>

                                                            </span>
                                                        </div>

                                                    );
                                            }

                                    }
                                )}

                                </div>
                            </Drawer>
                        </Space>
                        :
                        // ----------------------------------------------搜索后结果-------------------------------------------------
                        <Space className="area">
                            {/* <Affix offsetTop={86}>
                                <Tooltip title="点击返回" placement="top" defaultVisible="true">
                                    <div className="searchback" onClick={() => this.cancelmouseclick()}><LeftCircleOutlined /></div>
                                </Tooltip>
                            </Affix> */}
                            <div className="champions-area">
                                {this.state.searchresult.map(function (item, index) {
                                    // console.log("我是map循环：", item.name);
                                    let imgUrl = require('./championsavatar/' + item.name + '.png').default;
                                    return (
                                        <div className="hero animate__animated animate__fadeInDown" onClick={() => this.showDrawer(item)}>
                                            <img src={imgUrl} alt='' />
                                            <Tooltip zIndex={10001} title="Total" placement="top">
                                                <div className="herocornersum">{Object.keys(item.champion).length}</div>
                                            </Tooltip>
                                        </div>
                                    )
                                }.bind(this))}
                                {/* 实现自动换行最后一行左对齐 */}
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>
                                <div className="bt_classify_name_empty" style={hiddenstyle}></div>



                            </div>
                            <Drawer
                                // height={500}
                                headerStyle={headstyle}
                                drawerStyle={drawcontentstyle}
                                contentWrapperStyle={drawstyle}
                                bodyStyle={drawbodystyle}
                                // getContainer={false}
                                className="details"
                                placement="bottom"
                                destroyOnClose={true}
                                closable={true}
                                closeIcon={<div className='closeimg'></div>}
                                onClose={this.onClose}
                                visible={this.state.drawvisible}
                            >
                                {/* 弹出框标题 英雄名称和头像 */}
                                <div className='drawtitle'>

                                    <div className='drawtitlebox'>
                                        <p className='animate__animated animate__fadeInLeft'><img className='drawimg' src={this.state.searchimg} alt='' /></p>
                                        <p className='search_title animate__animated animate__fadeInRight'>{this.state.searchtitle}</p>
                                    </div>
                                    <Divider orientation="left" className='drawdivider'></Divider>
                                </div>

                                {/* 内容部分 合集名称和点赞 踩 */}
                                <div className='drawcontent'>
                                    {Object.keys(this.state.searchcontent).length == 0 ?
                                        
                                        <div><p className="search_content_not animate__animated animate__fadeInDown">不支持 ( Not Supported ) </p></div>
                                           
                                      :
                                      Object.keys(this.state.searchcontent).map((item, index) => {
                                        // 看合集踩
                                        
                                            switch (item) {
                                                //收费合集判断
                                                case 'SAAIO':
                                                // case 'AlexisAIO':
                                                case 'GGAIO':
                                                case 'IntAIO':
                                                case 'MarksmanAIO':
                                                case '[Xi]Vayne':
                                                case 'HGAIO':
                                                case 'RexEzreal':
                                                case 'US':
                                                case 'Yasuo-Forbidden Blade':
                                                case 'Yasuo-Windwalker':
                                                case 'SP-AIO':
                                                case 'JK-Pvt-AIO':
                                                    return (
                                                        <div className='search_content_paid_box animate__animated animate__fadeInDown'>
                                                            {/* <div className="search_content_paid"></div> */}
                                                            <p><img className='search_content_paid' src={require('./misc/coin-Dollar.png').default} alt=''></img></p>
                                                            <p className="search_content">{item}</p>
                                                            <span className="search_content_rate_box">
                                                                <div className='ratebox'>
                                                                    <Tooltip title="Vote: wonderful" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                </div>
                                                                <div className='ratebox'>
                                                                    <span className='filldown'></span>

                                                                    <Tooltip title="Vote: terrible" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>*</span>
                                                                    <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                </div>
                                                            </span>


                                                        </div>
                                                    );
                                                    break;
                                                    case 'AlexisAIO':
                                                        return (
                                                            <div className='search_content_paid_box animate__animated animate__fadeInDown'>
                                                                <p><img className='search_content_paid' src={require('./misc/coin-Dollar.png').default} alt=''></img></p>
                                                                <p className="search_content">{item}</p>
                                                                <p className='alexisqq'>
                                                                  <Tooltip title="click to buy" placement="top">
                                                                     <a href="https://alexisaio.com/" target="_blank" rel="noopener noreferrer"><ShoppingCartOutlined /></a>
                                                                  </Tooltip>
                                                                </p>
    
                                                                <span className="search_content_rate_box">
                                                                    <div className='ratebox'>
                                                                        <Tooltip title="Vote: wonderful" placement="top">
                                                                            <span className='animate__animated animate__rubberBand'>
                                                                                <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                            </span>
                                                                        </Tooltip>
                                                                        <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                    </div>
                                                                    <div className='ratebox'>
                                                                        <span className='filldown'></span>
                                                                        <Tooltip title="Vote: terrible" placement="top">
                                                                            <span className='animate__animated animate__rubberBand'>
                                                                                <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                            </span>
                                                                        </Tooltip>
                                                                        <span className='ratesum'>*</span>
                                                                        <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                    </div>
                                                                </span>
    
    
                                                            </div>
                                                        );
                                                        break;
                                                default:
                                                    return (
                                                        <div className='search_content_free_box animate__animated animate__fadeInDown'>
                                                            <p><img className='search_content_free' src={require('./misc/coin-Free.png').default} alt=''></img></p>
                                                            <p className="search_content">{item}</p>
                                                            <span className="search_content_rate_box">
                                                                <div className='ratebox'>
                                                                    <Tooltip title="Vote: wonderful" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioUp(this.state.searchtitle, item)} className="search_content_up" src={require('./misc/up_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>{this.state.searchcontent[item].up}</span>
                                                                </div>

                                                                <div className='ratebox'>
                                                                    <span className='filldown'></span>

                                                                    <Tooltip title="Vote: terrible" placement="top">
                                                                        <span className='animate__animated animate__rubberBand'>

                                                                            <img onClick={() => this.aioDown(this.state.searchtitle, item)} className="search_content_down" src={require('./misc/down_fill.png').default} />
                                                                        </span>
                                                                    </Tooltip>
                                                                    <span className='ratesum'>*</span>
                                                                    <span className='ratesum' style={hiddenstyle}>{this.state.searchcontent[item].down}</span>
                                                                </div>

                                                            </span>
                                                        </div>

                                                    );
                                            }

                                        }
                                    //   }
                                    )}

                                </div>
                            </Drawer>
                        </Space>
                    }
                    <div className="championbottomfill"></div>
                    <div className="footer">
                        <span className="author">
                            ©2022&nbsp;By&nbsp;<img className='footavatar' src={require('./misc/PurpleOcean.JPG').default} alt='' />&nbsp;PurpleOcean
                        </span>
                        <a
                            className="icpInfo"
                            href="https://beian.miit.gov.cn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            京ICP备2021014796号-3
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        state => ({
            id: state.personalData.id,
            tasks: state.tasks,
            isDark: state.personalData.isDark,
        }),
        {}
    )(HanBot)
);
