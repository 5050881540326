// 腾讯云开发的一些API
import cloudbase from '@cloudbase/js-sdk';
import tcb from '@cloudbase/js-sdk';
// import Highlighter from 'react-highlight-words';

// import electron_adapter from 'cloudbase-adapter-electron';
// import electron_adapter from 'cloudbase-adapter-wx_mp';

// import { SDKAdapterInterface } from '@cloudbase/adapter-interface';
// import { env } from '../secret/cloudbaseKey';


// cloudbase.useAdapters(electron_adapter);  

// 1. 初始化 SDK
// export const appTcb = tcb.init({
//     env:"env-2gm8325vd10521a9",
//     // 传入自定义登录私钥
//     // credentials: require("./login_key.json")
// });
export const app = cloudbase.init({
    env:"env-2gm8325vd10521a9",
    // 传入自定义登录私钥
    // credentials: require("./login_key.json"),
    // appAccess: {
    //     // 凭证
    //     'key': '90acd64355fd278a4d67030378bbb9bf',
    //     // 版本
    //     'version': '1'
    //   }

    
    appSign: 'com.hanbot3.app',
    appSecret: {
    appAccessKeyId: '1',
    appAccessKey: 'a7587390f9917324ead8b359dd012641'
  }
});

// 2. 开发者自定义的用户唯一身份标识
const customUserId = "login_key";

// 3. 创建ticket
// export const ticket = app.auth().createTicket(customUserId);

export const auth = app.auth({
    persistence: 'local',
});

// var auth2 = app.auth();
// async function login(){
//     await auth.signInAnonymously();
//     // 匿名登录成功检测登录状态isAnonymous字段为true
//     const loginState = await auth.getLoginState();
//     console.log(loginState.isAnonymous); // true
//   }
  
//   login();

// export const authTcb = appTcb.auth({
//     persistence: 'local',
// });

export const db = app.database();
